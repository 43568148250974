.div {
	display: flex;
	justify-content: center;

	.icon {
		stroke: black;
		background-color: rgb(236, 236, 236);
		border-radius: 50%;
		width: 2.5em;
		height: 2.5em;
		padding: 5px;
		box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
		cursor: pointer;
		position: relative;

		&.active {
			stroke: white;
			background-color: #00a58d;
		}
	}

	@media (max-width: 640px) {
		margin-bottom: 5px;
		justify-content: flex-end;
	}
}

.modal {
	position: absolute;
	background-color: #aac5c2;
	top: 0em;
	left: 6em;
	width: 100vh;
	height: 40vh;
	z-index: 1;
	border-radius: 5px;
	box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
	padding: 10px;
}

.main_table {
	border-spacing: 0px;
	border-collapse: collapse;

	thead {
		text-align: center;
	}

	.tr {
		&:hover .div_td {
			background-color: #9cc3bd !important;
			color: white;
		}

		.td {
			box-sizing: initial;
			padding: 0;
			padding-bottom: 10px;
			margin: 0;

			.div_td {
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #ececec;
				min-height: 4em;
				width: 100%;

				&.start {
					justify-content: flex-start;
				}

				&.first {
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
					padding-left: 10px;
				}

				&.last {
					border-top-right-radius: 5px;
					border-bottom-right-radius: 5px;
				}

				&.icon_div {
					display: flex;
					justify-content: space-around;

					.option {
						background-color: #818181;
						padding: 5px 10px;
						border-radius: 5px;
						border: 1px solid #6e6e6e;
						color: white;
						cursor: pointer;

						&:hover {
							background-color: #078976;
						}
					}
				}

				.icon {
					cursor: pointer;
					width: 2em;
					height: 2em;

					&.edit {
						fill: #289428;
					}

					&.delete {
						fill: #f44545;
					}
				}

				&.smallText {
					font-size: 0.8em;
					padding: 19px 0px 20px 0px;
				}

				@media (max-width: 640px) {
					min-height: 3em;
				}
			}
		}
	}
}

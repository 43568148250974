.div_main {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	.icon {
		width: 40%;

		@media (max-width: 640px) {
			width: 70%;
		}
	}
}

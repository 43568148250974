.div_evaluation {
	display: flex;
	position: absolute;
	background-color: #a2bcb8;
	min-height: 5em;
	z-index: 100;
	right: 2%;
	bottom: 10%;
	border-radius: 10px;
	min-width: 22em;
	box-shadow: 4px 3px 3px 0px black;
	cursor: pointer;

	.icon {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px 10px;

		.icon_feather {
			width: 2.5em;
			height: 2.5em;
			stroke: #097465;
		}
	}

	.content_evaluation {
		padding: 5px;
	}

	.pretitle {
		font-size: 13px;
		color: white;
	}

	.title {
		color: #097465;
		font-weight: 600;
	}

	.time {
		color: white;
		font-size: 15px;
	}
}

.mainHello {
	color: white;
	border-radius: 10px;
	background: linear-gradient(to bottom, #0d9488, #134e4a);
	background: -webkit-linear-gradient(top, #0d9488, #134e4a);
}

.cardBody {
	display: flex;

	.iconText {
		display: flex;
		width: 80%;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		font-weight: 500;
	}

	.iconSprite {
		position: relative;
		width: 54px;
		height: 54px;
		clip-path: polygon(
			40% 7.67949%,
			43.1596% 6.20615%,
			46.52704% 5.30384%,
			50% 5%,
			53.47296% 5.30384%,
			56.8404% 6.20615%,
			60% 7.67949%,
			81.65064% 20.17949%,
			84.50639% 22.17911%,
			86.97152% 24.64425%,
			88.97114% 27.5%,
			90.44449% 30.6596%,
			91.34679% 34.02704%,
			91.65064% 37.5%,
			91.65064% 62.5%,
			91.34679% 65.97296%,
			90.44449% 69.3404%,
			88.97114% 72.5%,
			86.97152% 75.35575%,
			84.50639% 77.82089%,
			81.65064% 79.82051%,
			60% 92.32051%,
			56.8404% 93.79385%,
			53.47296% 94.69616%,
			50% 95%,
			46.52704% 94.69616%,
			43.1596% 93.79385%,
			40% 92.32051%,
			18.34936% 79.82051%,
			15.49361% 77.82089%,
			13.02848% 75.35575%,
			11.02886% 72.5%,
			9.55551% 69.3404%,
			8.65321% 65.97296%,
			8.34936% 62.5%,
			8.34936% 37.5%,
			8.65321% 34.02704%,
			9.55551% 30.6596%,
			11.02886% 27.5%,
			13.02848% 24.64425%,
			15.49361% 22.17911%,
			18.34936% 20.17949%
		);
		background: linear-gradient(310.31deg, #ff3364 14.71%, #ff7f9e 100%);
		display: flex;
		align-items: center;
		justify-content: center;

		&.warning {
			background: linear-gradient(297.08deg, #ff9e2a -4.53%, #ffa941 98.25%);
		}
	}

	.iconFill {
		width: 26px;
		height: 26px;
		fill: #fff;
	}
}

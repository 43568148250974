.alertGenerate {
	width: 100%;
	padding: 10px;
	display: flex;
	justify-content: center;
	background: #fad292;
	border-radius: 10px;
}

.buttonGenerate {
	padding: 1em;
	text-align: center;
	background-color: #00a58d;
	border-radius: 10px;
	cursor: pointer;
	margin-top: 10px;
	margin-bottom: 10px;
	color: white;
}

.evaluationMenu {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-top: 1em;
	padding-bottom: 1em;
	cursor: pointer;
	border-radius: 5px;
	background-color: #87a5a0;
	color: white;
	min-height: 4em;

	&:hover {
		background-color: #068572;
	}

	&.active {
		background-color: #068572;
	}
}

.evaluationQuestion {
	padding: 1em;
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid #0c7f70;
	color: #00a58d;
	margin-left: 0px;
	margin-right: 0px;
	margin-bottom: 10px;
}

.actions > div {
	display: flex;
	justify-content: center;
	align-items: center;
}

.icon {
	width: 15%;
	height: 15%;
	cursor: pointer;
	margin-right: 5px;
}

.filter {
	.title {
		color: #00a58d;
	}

	.separator {
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.fields {
		padding-left: 0px;
		padding-right: 0px;

		.label {
			margin-bottom: 0px;
			margin-top: 7px;
			font-size: 13px;
		}
	}

	.button_apply {
		color: #289428;
		border: 1px solid #289428;
		display: flex;
		justify-content: center;
		padding: 5px 10px;
		border-radius: 5px;
		margin-bottom: 5px;
		cursor: pointer;

		&:hover {
			background-color: #289428;
			color: white;
		}
	}

	.button_clean {
		color: #3f51b5;
		border: 1px solid #3f51b5;
		display: flex;
		justify-content: center;
		padding: 5px 10px;
		border-radius: 5px;
		margin-bottom: 5px;
		cursor: pointer;

		&:hover {
			background-color: #3f51b5;
			color: white;
		}
	}
}

.main_review {
	padding-top: 15px;

	.pretitle {
		font-size: 13px;
		font-weight: 500;
	}

	.title {
		color: #00a58d;
		font-size: 20px;
	}

	.options {
		display: flex;
		margin-top: 5px;
		margin-bottom: 5px;

		.button {
			padding: 5px 8px;
			background-color: #6c757d;
			color: white;
			border-radius: 5px;
			cursor: pointer;
			margin-right: 5px;

			&.change {
				background-color: #00a58d;

				background-image: url("../../../assets/icons/refresh.svg");
				background-repeat: no-repeat;
				background-position: right;
				background-size: 1.5em;
				background-position: 105px 5px;
				padding-right: 45px;
			}
		}
	}

	.title_table {
		display: flex;
		font-size: 13px;
		font-weight: 500;
	}

	.div_user {
		background-color: #dee2e6;
		padding: 15px 10px;
		border: 1px solid #212529;
		border-radius: 5px;
		margin-bottom: 10px;
		display: flex;
	}

	.name {
		width: 25%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.review {
		width: 15%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.pending {
		width: 15%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.correct {
		width: 10%;
		display: flex;
		align-items: center;
		justify-content: center;

		&.value {
			font-weight: bold;
		}
	}
	.state {
		width: 15%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.action {
		width: 20%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media (max-width: 990px) {
		padding-right: 15px;
		padding-left: 15px;
	}
}

.question_finish {
	display: flex;

	.button_finish {
		padding: 10px 10px;
		border: 2px solid transparent;
		border-radius: 5px;
		font-size: 16px;
		font-weight: 400;
		animation: animationButton 1s infinite;
		cursor: pointer;

		&:hover {
			background-color: #00a18a;
			color: white;
			animation: none;
		}
	}

	@keyframes animationButton {
		0%,
		100% {
			border-color: transparent;
		}
		50% {
			border-color: #00a18a;
		}
	}
}

.question_review {
	.question {
		display: flex;
		flex-wrap: wrap;
		padding: 7px;
		background-color: #00a58d0d;
		border-radius: 5px;
		margin-bottom: 8px;
		border: 1px solid #00a18a;
		--tw-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
		--tw-shadow-colored: 0px 0px 1px var(--tw-shadow-color), 0px 0.5px 2px var(--tw-shadow-color);
		box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);

		.question_statement {
			width: 85%;
			display: flex;
			align-items: center;

			font-size: 20px;
			font-weight: 500;

			.question_badge {
				font-size: 12px;
				font-weight: 400;

				&.type_1 {
					background-color: #59667a !important;
					color: white;
				}

				&.type_2 {
					background-color: #59667a !important;
					color: white;
				}

				&.type_3 {
					background-color: #59667a !important;
					color: white;
				}

				&.type_4 {
					background-color: #59667a !important;
					color: white;
				}
			}
		}

		.question_option {
			display: flex;
			width: 15%;

			.icon_option {
				width: 3em;
				height: 3em;
				padding: 5px;
				border-radius: 50%;
				margin-right: 5px;
				cursor: pointer;

				&.delete {
					border: 1px solid #dc3545;

					&:hover {
						background-color: #dc3545;
						color: white;
						fill: white;
					}
				}

				&.refresh {
					border: 1px solid #00a58d;

					&:hover {
						background-color: #00a58d;
						color: white;
					}
				}

				&.confirm {
					border: 1px solid #51bb25;

					&.active {
						background-color: #51bb25;
						color: white;
					}

					&:hover {
						background-color: #51bb25;
						color: white;
					}
				}
			}

			@media (max-width: 575px) {
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}
	}

	.answer {
		display: flex;
		align-items: center;

		.text_check {
			font-size: 15px;
			font-weight: 500;
			color: #00a58d;
		}

		.icon_check {
			margin-left: 10px;
			width: 1.5em;
			height: 1.5em;
		}

		.is_true {
			font-size: 15px;
			font-weight: 500;
			color: #00a58d;
		}

		.is_false {
			font-size: 15px;
			font-weight: 500;
			color: #00a58d;
		}
	}
}

.is_correct {
	border: 1px solid #009718;
	padding: 1px 8px;
	border-radius: 5px;
	background-color: #bae9c2;
	color: #009718;
	margin-right: 5px;
}

.is_incorrect {
	border: 1px solid #970000;
	padding: 1px 8px;
	border-radius: 5px;
	background-color: #e9baba;
	color: #970000;
	margin-right: 5px;
}

.save_button {
	width: 16%;
	background-color: #0f172a;
	color: white;
	margin-top: 10px;
	padding: 8px 10px;
	border-radius: 5px;
	cursor: pointer;

	&.disabled {
		background-color: #6c757d;
		cursor: not-allowed;
	}
}

.students {
	.button {
		margin-right: 10px;
		margin-bottom: 10px;
	}

	.course {
		background-color: #e9ecef;
		padding: 10px 15px;
		border-radius: 5px;
		border: 1px solid #6c757d;
		margin-bottom: 5px;
		cursor: pointer;
		display: flex;
		justify-content: space-between;

		&.selected {
			background-color: #0ea38d40;
			border: 1px solid #00a58d;
			color: #1d1e26;
		}
	}
}

.filter {
	.title {
		color: #00a58d;
	}

	.separator {
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.fields {
		padding-left: 0px;
		padding-right: 0px;

		.label {
			margin-bottom: 0px;
			margin-top: 7px;
			font-size: 13px;
		}
	}

	.button_apply {
		color: #289428;
		border: 1px solid #289428;
		display: flex;
		justify-content: center;
		padding: 5px 10px;
		border-radius: 5px;
		margin-bottom: 5px;
		cursor: pointer;

		&:hover {
			background-color: #289428;
			color: white;
		}
	}

	.button_clean {
		color: #3f51b5;
		border: 1px solid #3f51b5;
		display: flex;
		justify-content: center;
		padding: 5px 10px;
		border-radius: 5px;
		margin-bottom: 5px;
		cursor: pointer;

		&:hover {
			background-color: #3f51b5;
			color: white;
		}
	}
}

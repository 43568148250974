.courses {
	.rowAction {
		margin-top: 10px;
		margin-bottom: 10px;
		display: flex;
		justify-content: center;

		.buttonAction {
			margin-left: 5px;
			margin-right: 5px;
		}
	}

	.rowTableStudents {
		margin-top: 15px;
		margin-bottom: 15px;

		.divButton {
			display: flex;
			justify-content: center;
			margin-top: 10px;
			margin-bottom: 10px;

			.button {
				min-width: 5em;
				margin: 0px 5px;
				background-color: #00a58d;
				color: white;
				padding: 10px 10px;
				text-align: center;
				cursor: pointer;
				font-size: 15px;
				border-radius: 5px;
				padding-left: 3em;

				&.excel_down {
					background-image: url("../../../assets/icons/down.svg");
					background-size: 30px;
					background-repeat: no-repeat;
					background-position: 5px 6px;
					stroke: white;
				}

				&.excel_up {
					background-image: url("../../../assets/icons/up.svg");
					background-size: 30px;
					background-repeat: no-repeat;
					background-position: 5px 6px;
					stroke: white;
				}

				&.delete {
					background-image: url("../../../assets/icons/delete.svg");
					background-size: 30px;
					background-repeat: no-repeat;
					background-position: 5px 6px;
					stroke: white;
				}

				&:hover {
					background-color: #228173;
				}

				&.fetching {
					background-color: #b4c7c4;
				}
			}
		}
	}

	.noRegistered {
		display: flex;
		justify-content: center;
		margin-top: 3em;
		margin-bottom: 3em;
		font-size: 20px;
	}

	.tableResume {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0px 8px;

		.countElement {
			padding: 6px;
			color: white;
			border-radius: 10px;

			&.students {
				background-color: #1d9f2c;
			}
			&.answered {
				background-color: #3f51b5;
			}
			&.pending {
				background-color: #ff9800;
			}
		}
	}
}

.courseEvaluationFilters {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 10px;

	.FilterButton {
		padding-left: 1em;
		padding-right: 1em;
		border-radius: 5px;
		padding-top: 10px;
		padding-bottom: 10px;
		min-width: 8em;
		text-align: center;
		border: 1px solid #00a58d;
		color: #00a58d;
		margin-left: 5px;
		margin-right: 5px;
		margin-bottom: 5px;
		cursor: pointer;

		&:hover {
			background-color: #00a58d;
			color: white;
		}

		&.active {
			background-color: #00a58d;
			color: white;
		}
	}
}

.registerCourse {
	padding-left: 10em;
	padding-right: 10em;

	.divRegister {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		flex-direction: column;
	}

	.rowInput {
		padding-bottom: 1em;
	}

	.button {
		background-color: #2dae9b;
		color: white;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		text-align: center;
		cursor: pointer;
		font-size: 20px;
		border-radius: 5px;

		&:hover {
			background-color: #228173;
		}

		&.fetching {
			background-color: #b4c7c4;
			background-color: #b4c7c4;
		}
	}

	.iconRegister {
		width: 20em;
	}
}

.buttonGo {
	display: flex;
	justify-content: center;
	align-items: center;
}

.listCourses {
	.succesBadge {
		background-color: #219419;
		color: white;
	}

	.errorBadge {
		background-color: #d62424;
		color: white;
	}

	.span_quantity {
		margin-bottom: 2px;
	}

	.filter {
		display: flex;
		margin-bottom: 10px;

		.button {
			background-color: #dee2e6;
			padding: 5px 25px;
			border-radius: 5px;
			border: 1px solid #98a6ad;
			margin-right: 10px;
			cursor: pointer;

			&.active_success {
				background-color: #219419;
				border: 1px solid #219419;
				color: white;
			}

			&.active_error {
				background-color: #dc3545;
				border: 1px solid #dc3545;
				color: white;
			}
		}
	}
}

.courseHeaderContainerSpan {
	padding-bottom: 5px;

	.courseHeaderSpan {
		padding: 5px;
		border-radius: 5px;
		color: white;
		margin-right: 5px;

		&.level {
			background-color: #4caf50;
		}

		&.subject {
			background-color: #2196f3;
		}
	}
}

.divNotFound {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	align-items: center;

	h3 {
		margin-top: 2em;
		color: #00a58d;
	}

	img {
		width: 25em;
	}
}

.filter {
	.title {
		color: #00a58d;
	}

	.separator {
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.fields {
		padding-left: 0px;
		padding-right: 0px;

		.label {
			margin-bottom: 0px;
			margin-top: 7px;
			font-size: 13px;
		}
	}

	.button_apply {
		color: #289428;
		border: 1px solid #289428;
		display: flex;
		justify-content: center;
		padding: 5px 10px;
		border-radius: 5px;
		margin-bottom: 5px;
		cursor: pointer;

		&:hover {
			background-color: #289428;
			color: white;
		}
	}

	.button_clean {
		color: #3f51b5;
		border: 1px solid #3f51b5;
		display: flex;
		justify-content: center;
		padding: 5px 10px;
		border-radius: 5px;
		margin-bottom: 5px;
		cursor: pointer;

		&:hover {
			background-color: #3f51b5;
			color: white;
		}
	}
}

.buttonUploadFile {
	width: 40%;
	color: #00a58d;
	border: 1px solid #00a58d;
	background-color: white;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	text-align: center;

	&:hover {
		background-color: #00a58d;
		color: white;
	}
}

@media (max-width: 640px) {
	.registerCourse {
		padding-left: 1em;
		padding-right: 1em;
	}
}

.divInitial {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 5px 15em;

	.textStart {
		padding-left: 2em;
		padding-right: 2em;
		padding-bottom: 0.5em;
		padding-top: 0.5em;
		text-align: center;
		font-size: 20px;
	}

	.subtextStart {
		padding-left: 2em;
		padding-right: 2em;

		p {
			margin-bottom: 7px;
		}
	}

	.ready {
		margin-top: 15px;
		margin-bottom: 15px;
		color: #0f172a;
	}

	.buttonStart {
		cursor: pointer;
		padding: 0.5em 5em;
		border-radius: 5px;
		background-color: #00a68e;
		color: white;

		&:hover {
			background-color: #008d79;
		}
	}

	.IconStart {
		cursor: pointer;
		width: 27em;
	}
}

.divInCourse {
	padding-left: 2em;
	padding-right: 2em;

	.title {
		margin-top: 15px;

		.div_complete {
			width: 64%;
			margin-bottom: 5px;
			background-color: #a8a8a8;
			border-radius: 5px;

			.div_complete_active {
				height: 7px;
				border-radius: 5px;
				width: 50%;
			}
		}
	}

	.globalTime {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 4em;
	}

	.divButtons {
		overflow-x: auto;
		display: flex;
		width: 100%;

		&::-webkit-scrollbar {
			width: 5px;
			height: 10px;
		}

		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #a8a8a8;
			border-radius: 5px;
		}

		.buttonNumber {
			background-color: #a2bcb8;
			min-width: 5em;
			text-align: center;
			padding: 0.5em;
			border-radius: 5px;
			color: white;
			cursor: pointer;
			margin-right: 5px;
			margin-bottom: 10px;

			&.active {
				background-color: #0daa94;
			}

			&.selected {
				background-color: #144c44;
				border: 2px solid #0daa94;
			}
		}
	}
}

.divCarousel {
	max-width: 17em;
	width: 50%;
	margin: auto;
	margin-top: 10px;
}

.divQuestion {
	text-align: center;
	margin-top: 1em;
	margin-bottom: 1em;
	font-size: 20px;

	.divQuestionNumber {
		text-align: center;
		margin-right: 15px;
		background-color: #00a68e;
		color: white;
		padding-left: 0.5em;
		padding-right: 0.5em;
		padding-top: 6px;
		padding-bottom: 6px;
		border-radius: 50%;
	}
}

.divConfirm {
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;

	.button {
		background-color: #0daa94;
		width: 49%;
		margin-top: 10px;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		padding-left: 1em;
		padding-right: 1em;
		text-align: center;
		border-radius: 5px;
		color: white;
		cursor: pointer;

		&.confirm {
			padding-top: 1em;
			padding-bottom: 1em;
			background-color: #0daa94;
			width: 68%;
			margin-left: 10px;
		}

		&.finish {
			padding-top: 1em;
			padding-bottom: 1em;
			background-color: #0f172a;
			width: 30%;
		}
	}
}

.questionSentence {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	margin-right: auto;
	margin-left: auto;
	width: 50%;

	.openText {
		height: 10em;
		width: 75vh;

		@media (max-width: 575px) {
			min-width: 40vh;
		}
	}
}

.questionConnect {
	margin-top: 1em;

	.divOption {
		background-color: #a2bcb8;
		text-align: center;
		padding-top: 0.7em;
		padding-bottom: 0.7em;
		border-radius: 10px;
		margin-bottom: 10px;
		color: white;
		cursor: pointer;
	}
}

.alternative {
	background-color: #efefef;
	padding: 20px 10px;
	border-radius: 10px;
	border: 1px solid #98a6ad;
	margin-bottom: 15px;
	font-size: 18px;
	cursor: pointer;

	&.selected {
		color: #00a58d;
		border: 1px solid #00a58d;
		background-color: #0daa9421;
	}
}

.tof {
	display: flex;
	justify-content: center;

	.option {
		color: black;
		width: 30%;
		border: 1.5px solid gray;
		border-radius: 10px;
		padding: 2em 3em;
		background-color: #dee2e6;
		cursor: pointer;
		text-align: center;
		font-size: 25px;
		margin: 0px 20px;

		&.true {
			background-color: #13aa0d36;
			color: green;
			border: 1.5px solid green;
		}

		&.false {
			background-color: #aa0d0d36;
			color: #e13314;
			border: 1.5px solid #e13314;
		}
	}
}

@media (max-width: 575px) {
	.divCarousel {
		width: 100%;
	}
}

@media (max-width: 475px) {
	.divCarousel {
		width: 100%;
	}

	.questionSentence {
		width: 100%;
	}

	.divInCourse {
		padding-left: 0em;
		padding-right: 0em;
	}
}

// TABLE
.base-table {
	width: 100%;
	border-collapse: collapse;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	border-style: hidden;
	box-shadow: 0 0 0 1px $primary-color;
}

.base-table td,
.base-table th {
	border: 1px solid #ddd;
	padding: 8px;
}

.base-table th {
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: left;
	background-color: $primary-color;
	color: white;
	text-align: center;
	font-weight: 400;
}

.base-table th:last-child {
	border-top-right-radius: 10px;
}

.base-table th:first-child {
	border-top-left-radius: 10px;
}

.base-table {
	.table-actions {
		cursor: pointer;

		.icon-delete {
			width: 1.5em;
			height: 1.5em;
			fill: #e4566e;
			margin-left: 0.5em;
			margin-right: 0.5em;
		}
		.icon-edit {
			width: 1.5em;
			height: 1.5em;
			fill: rgb(40, 167, 69);
			margin-left: 0.5em;
			margin-right: 0.5em;
		}
	}
}

// TABLE SECONDARY
.secondary-table {
	width: 100%;
	border-collapse: collapse;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	border-style: hidden;
	box-shadow: 0 0 0 1px #0f172a;
}

.secondary-table td,
.secondary-table th {
	border: 1px solid #ddd;
	padding: 8px;
}

.secondary-table th {
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: left;
	background-color: #0f172a;
	color: white;
	text-align: center;
	font-weight: 400;
}

.secondary-table th:last-child {
	border-top-right-radius: 10px;
}

.secondary-table th:first-child {
	border-top-left-radius: 10px;
}

.secondary-table {
	.actions {
		display: flex;
		justify-content: center;
	}

	.table-actions {
		cursor: pointer;

		.icon-delete {
			width: 1.5em;
			height: 1.5em;
			fill: #e4566e;
			margin-left: 0.5em;
			margin-right: 0.5em;
		}
		.icon-edit {
			width: 1.5em;
			height: 1.5em;
			fill: rgb(40, 167, 69);
			margin-left: 0.5em;
			margin-right: 0.5em;
		}
	}
}

// CARDS
.card-body {
	padding: 30px !important;
}

.form-tab-detail {
	margin-top: 1em;
}

// PAGINADOR
.base-paginator {
	padding-top: 10px;
	padding-bottom: 1em;
}

// FETCHING
.fetching-data {
	text-align: center;
	padding: 3em;
	color: $primary-color !important;
}

// MODAL
.modal-title {
	font-size: 1em !important;
}

// LIST
.header-table {
	margin-bottom: 1em;

	.label-pagination {
		font-size: 15px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-content: center;
		flex-direction: column;
	}
}

.pagination-right {
	float: right;
}

.main-action {
	margin-bottom: 5px;
}

.action {
	background-color: var(--theme-deafult);
	border-radius: 10px;
	padding: 0.5em;
	text-align: center;
	margin: auto;
	color: white;
	font-weight: 500;
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-content: center;

	.icon {
		width: 1.5em;
		height: 1.5em;
		margin-left: 5px;
		margin-right: 5px;
		fill: white;
	}

	&:hover {
		background-color: var(--theme-secondary);
		color: white;
	}
}

// Question
.question-type {
	padding: 1em;
	display: flex;
	align-content: center;
	justify-content: center;
	background: #7982b4;
	color: white;
	margin-bottom: 10px;
	border-radius: 10px;
	cursor: pointer;
}

.card-header {
	padding: 15px 15px !important;

	.card-title {
		font-size: 15px;
	}
}

.card-body {
	padding: 15px 15px !important;
}

.load-all-page {
	top: 80vh;
	left: 2%;
	z-index: 99999;
	position: absolute;
	background-color: #f5f7f6;
	padding: 10px 20px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 4px 4px 6px #232323;
}

.page-wrapper {
	.page-body-wrapper {
		.page-title {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}

.form-label {
	margin-bottom: 2px;
}

.nav-link {
	color: #00a58d;
}

@media (max-width: 575px) {
	.label-pagination {
		text-align: right;
	}
}

@media (max-width: 640px) {
	.responsiveTable tbody tr {
		border: initial !important;
		border-bottom: 1px solid $primary-color !important;
		text-align: right;
		border-collapse: collapse;
		border-top-right-radius: 30px;
		border-top-left-radius: 30px;
	}
}

.optionDiv {
	position: relative;

	.optionMenu {
		position: absolute;
		background-color: #f8f8f8;
		z-index: 1;
		width: 14em;
		top: 120%;
		left: -170%;
		border-radius: 5px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
		color: black;
		display: none;

		&.show {
			display: block;
		}

		&:hover {
			color: black;
		}

		.optionTitle {
			padding: 2px 9px;
			font-size: 1em;
			font-weight: bold;
			color: #00a58d;
		}

		.optionHr {
			margin: 2px 0px;
		}

		.optionsItems {
			display: flex;
			flex-direction: column;
			align-content: flex-start;
			height: 100%;
			overflow-y: auto;

			.optionItem {
				padding: 2px 9px;
				font-size: 0.9em;
				cursor: pointer;
				text-align: start;
				color: black;
				text-decoration: none;

				.icon {
					margin-right: 5px;
				}

				&:hover {
					background-color: #e8e8e8;
				}
			}
		}
	}
}

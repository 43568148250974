.title {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	text-align: center;

	.subtitle {
		font-weight: 500;
	}

	.title_evaluation {
		font-size: 25px;
		color: #00a58d;
		font-weight: 500;
	}
}

.graph {
	display: flex;
	justify-content: center;
	margin-top: 1em;
	margin-bottom: 2em;
}

.details {
	display: flex;
	justify-content: center;
	margin-bottom: 10px;

	.is_question {
		border: 1px solid #02366a;
		padding: 1px 8px;
		border-radius: 5px;
		background-color: #c2c4f5;
		color: #02366a;
		margin-right: 5px;
	}

	.is_correct {
		border: 1px solid #009718;
		padding: 1px 8px;
		border-radius: 5px;
		background-color: #bae9c2;
		color: #009718;
		margin-right: 5px;
	}

	.is_incorrect {
		border: 1px solid #970000;
		padding: 1px 8px;
		border-radius: 5px;
		background-color: #e9baba;
		color: #970000;
		margin-right: 5px;
	}

	.is_pending {
		border: 1px solid #444444;
		padding: 1px 8px;
		border-radius: 5px;
		background-color: #bfbfbf;
		color: #444444;
		margin-right: 5px;
	}
}

.questions {
	margin-top: 1em;

	.question_div {
		padding-top: 10px;
		display: flex;
		justify-content: center;

		@media (max-width: 475px) {
			flex-direction: column;
			align-items: center;
		}

		&.par {
			background-color: #dee2e673;
		}
		&.impar {
			background-color: #00a68e29;
		}

		.number {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			color: #49b6a6;
			font-weight: 600;
			width: 3%;

			@media (max-width: 475px) {
				width: 100%;
				justify-content: flex-start;
				padding-left: 15px;
			}
		}

		.question {
			width: 97%;
			border: 1px solid #878787;
			padding: 15px 10px;
			border-radius: 10px;
			background-color: #f1f1f1;

			&.is_teacher {
				width: 90%;
			}

			@media (max-width: 475px) {
				width: 98%;
			}
		}

		.icons {
			width: 7%;
			display: flex;
			justify-content: center;
			align-items: center;

			@media (max-width: 475px) {
				width: 100%;
			}

			.icon {
				margin-left: 3px;
				width: 2em;
				height: 2em;
				cursor: pointer;

				@media (max-width: 475px) {
					margin-top: 5px;
					width: 2.5em;
					height: 2.5em;
				}
			}
		}
	}

	.answer_div {
		padding-bottom: 15px;

		&.par {
			background-color: #dee2e673;
		}
		&.impar {
			background-color: #00a68e29;
		}

		.answer_title {
			font-weight: bold;
			padding: 0px 40px;

			@media (max-width: 475px) {
				padding: 0px 5px;
			}
		}

		.answer_detail {
			padding: 0px 40px;

			@media (max-width: 475px) {
				padding: 0px 5px;
			}
		}

		.answer_response {
			display: flex;
			padding: 0px 40px;
			margin-top: 8px;

			// border-bottom: 1px solid #49b6a6;
			// padding-bottom: 20px;

			.is_correct {
				border: 1px solid #009718;
				padding: 1px 8px;
				border-radius: 5px;
				background-color: #bae9c2;
				color: #009718;
				margin-right: 5px;
			}

			.is_incorrect {
				border: 1px solid #970000;
				padding: 1px 8px;
				border-radius: 5px;
				background-color: #e9baba;
				color: #970000;
				margin-right: 5px;
			}

			.is_pending {
				border: 1px solid #444444;
				padding: 1px 8px;
				border-radius: 5px;
				background-color: #bfbfbf;
				color: #444444;
				margin-right: 5px;
			}

			@media (max-width: 475px) {
				padding: 0px 5px;
			}
		}
	}
}

.question_finish {
	display: flex;
	margin-bottom: 1em;
	justify-content: center;

	.button_finish {
		padding: 10px 10px;
		border: 2px solid transparent;
		border-radius: 5px;
		font-size: 16px;
		font-weight: 400;
		animation: animationButton 1s infinite;
		cursor: pointer;

		&:hover {
			background-color: #00a18a;
			color: white;
			animation: none;
		}
	}

	@keyframes animationButton {
		0%,
		100% {
			border-color: transparent;
		}
		50% {
			border-color: #00a18a;
		}
	}
}

.addFormItem {
	padding: 10px;
	background-color: #00a58d;
	border-radius: 10px;
	width: 35%;
	text-align: center;
	color: white;
	cursor: pointer;
}

.alternative {
	margin-bottom: 10px;
	padding: 15px;
	background-color: #ffdbdbb8;
	cursor: pointer;

	&.isCorrect {
		background-color: #e7ffe5;
	}

	.colIcon {
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			cursor: pointer;
			width: 1.5em;
			height: 1.5em;
		}
	}
}

.answer {
	margin-bottom: 10px;
	padding: 15px;
	background-color: #f5f5f5;

	.colIcon {
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			cursor: pointer;
			width: 1.5em;
			height: 1.5em;
		}
	}
}

.buttonTrueFalse {
	background-color: #f5f5f5;
	font-size: 20px;
	cursor: pointer;
	padding: 1em;
	text-align: center;
	border-radius: 10px;

	&.false {
		color: #6e0707;
		border: 1px solid #6e0707;
		background-color: #f3c2c285;
	}

	&.true {
		color: #076e07;
		border: 1px solid #076e07;
		background-color: #c2f3c285;
	}
}

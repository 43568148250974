.filter {
	.title {
		color: #00a58d;
	}

	.separator {
		color: #00a58d;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.fields {
		padding-left: 0px;
		padding-right: 0px;

		.label {
			margin-bottom: 0px;
			margin-top: 7px;
			font-size: 13px;
		}
	}

	.filterButtons {
		display: flex;
		justify-content: space-between;
		margin-top: 15px;

		.button_apply {
			width: 48%;
			background-color: #289428;
			color: white;
			border: 1px solid #289428;
			display: flex;
			justify-content: center;
			padding: 5px 10px;
			border-radius: 5px;
			margin-bottom: 5px;
			cursor: pointer;

			&:hover {
				background-color: white;
				color: #289428;
				border: 1px solid #289428;
			}
		}

		.button_clean {
			width: 48%;
			background-color: #3f51b5;
			color: white;
			border: 1px solid #3f51b5;
			display: flex;
			justify-content: center;
			padding: 5px 10px;
			border-radius: 5px;
			margin-bottom: 5px;
			cursor: pointer;

			&:hover {
				background-color: white;
				color: #3f51b5;
				border: 1px solid #3f51b5;
			}
		}
	}
}
